<template>
  <div>
    <div class="game_slide">
      <h2 class="title fontM">해피테이블 인기 게임</h2>
      <splide
        :options="options"
        @splide:move="onMove"
        @splide:pagination:updated="onPaginationUpdated"
      >
        <splide-slide
          v-for="game in games"
          :key="game.id"
          :class="{['slide' + game.id]: true}"
        >
          <template>
            <div>
              <div class="slide-info">
                <h2 class="fontM">{{ game.name_kor }}</h2>
                <h2 class="game_name fontM">{{ game.name }}</h2>
                <div class="paddingTB1 flex-set game_text">
                  <span class="game_text fontS">{{ game.text }}</span>
                </div>
                <div class="link">
                  <router-link
                    :to="{name: 'game', params: {game_name: game.name_eng}}"
                    ><div>
                      <span class="p-relative">자세히 보기</span>
                    </div></router-link
                  >
                </div>
              </div>
              <div class="imgWrap">
                <img :src="getSrc(`${game.name_eng}`)" />
              </div>
            </div>
          </template>
        </splide-slide>
      </splide>
      <div class="con_kind">
        <p class="fontExtraBold">GAME</p>
      </div>
    </div>
    <div class="game_info">
      <div class="btn marginAuto">
        <router-link to="/game" class="main_btn">
          <span class="fontLight fontS">게임 전체보기</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {Splide, SplideSlide} from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import game_content from "../assets/source/json/slide_gameInfo.json";
export default {
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      options: {
        type: "loop",
        speed: 1500,
        padding: "20%",
        autoplay: false,
        rewind: true,
        interval: 7000,
        // heightRatio: 0.3,
        perPage: 1,
        height: "300px",
        // pauseOnHover: true,
        gap: "0rem",
        focus: "center",
        trimSpace: true,
        pagination: true,
        breakpoints: {
          1023: {
            padding: "0%",
            height: "unset",
          },
        },
      },
      padding_size: "0%",
      splide_idx: 0,
      count: 0,
      previous_main_idx: 1,
      games: game_content,
    };
  },

  methods: {
    getSrc(name) {
      var con_name = name + "1";
      var images = require.context(
        "../assets/source/images/screenshots/",
        false,
        /\.jpg$/
      );
      return images("./" + con_name + ".jpg");
    },
    onMove() {
      var button_current = document.getElementsByClassName(
        "splide__pagination__page is-active"
      );
      this.previous_main_idx = parseInt(
        button_current[0].getAttribute("aria-label").replace("Go to slide ", "")
      ); // 현재 center 슬라이드
    },
    onPaginationUpdated() {
      this.splide_idx = document.getElementsByClassName(
        "splide__pagination__page"
      ).length;
      var button_current = document.getElementsByClassName(
        "splide__pagination__page is-active"
      );
      var current_main_idx = parseInt(
        button_current[0].getAttribute("aria-label").replace("Go to slide ", "")
      ); // 바뀐 center 슬라이드

      var previous_pre_idx; // 바뀌기 전 메인 슬라이드의 왼쪽 슬라이드
      var previous_next_idx; // 바뀌기 전 메인 슬라이드의 오른쪽 슬라이드
      var current_pre_idx; // 바뀐 후 메인 슬라이드의 왼쪽 슬라이드
      var current_next_idx; // 바뀐 후 메인 슬라이드의 오른쪽 슬라이드

      if (this.previous_main_idx == 1) {
        previous_pre_idx = this.splide_idx;
      } else {
        previous_pre_idx = this.previous_main_idx - 1;
      }
      if (this.previous_main_idx == this.splide_idx) {
        previous_next_idx = 1;
      } else {
        previous_next_idx = this.previous_main_idx + 1;
      }

      // 이전에 붙은 previous, next 클래스 삭제
      var previous_before_slide = document.getElementsByClassName(
        "slide" + previous_pre_idx
      );
      for (var p = 0; p < previous_before_slide.length; p++) {
        previous_before_slide[p].classList.remove("previous");
      }
      var previous_next_slide = document.getElementsByClassName(
        "slide" + previous_next_idx
      );
      for (var n = 0; n < previous_next_slide.length; n++) {
        previous_next_slide[n].classList.remove("next");
      }

      // 바뀔 슬라이드 인덱스 기준으로 새로운 next, previous 클래스 붙여주기
      // previous
      if (current_main_idx == 1) {
        current_pre_idx = this.splide_idx;
      } else {
        current_pre_idx = current_main_idx - 1;
      }
      // next
      if (current_main_idx == this.splide_idx) {
        current_next_idx = 1;
      } else {
        current_next_idx = current_main_idx + 1;
      }

      var current_before_slide = document.getElementsByClassName(
        "slide" + current_pre_idx
      );
      var current_next_slide = document.getElementsByClassName(
        "slide" + current_next_idx
      );
      for (var i = 0; i < current_before_slide.length; i++) {
        current_before_slide[i].className += " previous";
      }
      for (var j = 0; j < current_next_slide.length; j++) {
        current_next_slide[j].className += " next";
      }
    },
  },
};
</script>

<style>
.game_slide {
  background-color: #2b2e35;
}
h2.title {
  padding: 3rem 0;
  color: white;
  font-weight: 500;
}
li.splide__slide {
  padding: 1rem 0;
}

img {
  border-radius: 10px;
}
/* 지우면 안되는거 */
.splide__pagination {
  /* top: 79%; */
  left: 28%;
  /* z-index: -1; */
  /* bottom: unset; */
  width: unset;
  -webkit-animation: 0s linear 0s pagination_opacity forwards;
  animation: 0s linear 0s pagination_opacity forwards;
}
.splide__pagination li {
  padding: 0 0.3rem;
}
.splide__pagination__page {
  opacity: 1;
}
.splide__pagination__page.is-active {
  transform: unset;
  background-color: #de385e;
}
.splide__arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  opacity: 1;
  background: #fff;
}
.splide__arrow:hover {
  opacity: 1;
}
.splide__slide {
  overflow: hidden;
  line-height: 1.5;
}

.splide__arrow.splide__arrow--prev {
  left: 16% !important;
}
.splide__arrow.splide__arrow--next {
  right: 16% !important;
}
.slide-info {
  position: relative;
  display: block;
  width: 40%;
  margin-left: 5%;
}
/* 지우면 안되는거 끝 */

li .imgWrap {
  position: absolute;
  top: 0;
  left: 5%;
  z-index: 999;
}
li.previous .imgWrap {
  position: absolute;
  top: 0;
  left: 50%;
  /* transition: 1s; */
}
li.is-visible .imgWrap {
  position: absolute;
  top: 0;
  left: 5%;
  z-index: 2;
  animation: 1s linear 0s sliding forwards;
}

li.is-visible.next .imgWrap {
  animation: 0.8s ease-in-out 0s back_sliding forwards;
}
@keyframes back_sliding {
  from {
    left: 50%;
  }
  to {
    left: 5%;
  }
}
@keyframes sliding {
  from {
    left: 5%;
  }
  to {
    left: 50%;
  }
}

li.is-visible {
  color: white;
  text-align: left;
}
li.next .imgWrap {
  position: absolute;
  top: 0;
  left: 5%;
}
li.is-visible h2 {
  color: white;
  font-weight: 500;
}
li.is-visible h2.game_name {
  color: #de385e;
  font-weight: 500;
}

.link a {
  color: white !important;
  text-decoration: none;
  font-weight: 100 !important;
}
.link span:after {
  position: absolute;
  content: "→";
  font-size: 2rem;
  margin-left: 5px;
}
.link span:before {
  content: "";
  position: absolute;
  right: -43px;
  top: 0;
  z-index: -1;
  font-size: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  bottom: 40px;
  background: #de385e;
  display: block;
}
.con_kind {
  font-size: 17rem;
  background-color: #2b2e35;
  display: flex;
  justify-content: flex-end;
  line-height: 0.82;
  overflow: hidden;
}
.con_kind p {
  position: relative;
  top: 6px;
  right: -12px;
  color: #24272e;
}

a.main_btn {
  text-decoration: none !important;
  /* color: white !important; */
}
.btn a {
  color: white !important;
}

@media (min-width: 1601px) and (max-width: 1900px) {
  .splide__arrow.splide__arrow--prev {
    left: 18% !important;
  }
}
@media (min-width: 1601px) {
  .splide__pagination {
    left: 26.5%;
  }
}

@media (min-width: 1281px) and (max-width: 1600px) {
  .splide__pagination {
    left: 28%;
  }
  .splide__arrow.splide__arrow--prev {
    left: 17.5% !important;
  }
  .splide__arrow.splide__arrow--next {
    right: 15% !important;
  }
  .slide-info h2 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .splide__pagination {
    left: 30%;
  }
  .splide__arrow.splide__arrow--prev {
    left: 17.5% !important;
  }
  .splide__arrow.splide__arrow--next {
    right: 15% !important;
  }
}
@media (max-width: 1023px) {
  .splide__arrow.splide__arrow--prev {
    left: 5% !important;
  }
  .splide__arrow.splide__arrow--next {
    right: 5% !important;
  }
  .splide__pagination__page {
    background-color: transparent;
  }
  .splide__pagination__page.is-active {
    transform: unset;
    background-color: transparent !important;
  }
  .slide-info {
    padding: 1rem 3rem;
  }
  li.is-visible .imgWrap {
    position: unset;
    top: 0;
    /* display: flex;
    justify-content: center; */
    width: 70%;
    height: 100%;
    margin: 0 auto;
  }
  li.splide__slide {
    padding-top: 1rem !important;
    padding: unset !important;
  }
  .imgWrap img {
    width: 100%;
    height: unset !important;
  }
  .slide-info {
    text-align: center;
    width: 50%;
    margin: 0 auto;
  }
  li.previous .imgWrap {
    position: unset;
  }
  li .imgWrap {
    position: unset;
    top: 0;
    z-index: 999;
    width: 70%;
    height: 100% !important;
    margin: 0 auto;
  }
  li h2,
  .game_text {
    color: white;
    font-weight: 500;
  }
  li .game_name {
    color: deeppink;
    font-weight: 500;
  }
  h2.title {
    padding: 3rem 0 1rem 0;
    color: white;
    font-weight: 500;
  }
  .fontM {
    font-size: 1.2rem;
  }
}
@media (max-width: 430px) {
  .slide-info {
    text-align: center;
    width: 60%;
    margin: 0 auto;
  }
  .imgWrap img {
    width: 100%;
    height: unset !important;
  }
  li .imgWrap {
    position: unset;
    top: 0;
    z-index: 999;
    width: 70%;
    height: 100% !important;
    margin: 0 auto;
  }

  .splide__arrow {
    width: 2.5rem;
    height: 2.5rem;
  }
}
@media (max-width: 767px) {
  /* .splide__pagination {
    z-index: -1;
    bottom: 0;
    width: unset;
    animation: 0s linear 0s pagination_opacity forwards;
  } */
}
</style>

<style scoped>
.imgWrap {
  height: 300px;
}
.imgWrap img {
  height: 100%;
}
.game_info {
  margin: 0 auto;
  position: relative;
  top: -160px;
  height: 0;
}
.game_text.flex-set {
  justify-content: left;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .game_info {
    top: -100px;
  }
  .con_kind {
    font-size: 12rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .game_info {
    top: -90px;
  }
  .con_kind {
    font-size: 10rem;
  }
  .game_text.flex-set {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .con_kind {
    font-size: 9rem;
  }
  .game_info {
    top: -90px;
  }
  .con_kind p {
    top: 3px;
  }
  .game_text.flex-set {
    justify-content: center;
  }
}
</style>
